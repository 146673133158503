import { AdditionalInfo } from "./additionalInfo";
import { Form } from "./form";

// TODO: import when/if used
// import Gallery from "./imageSwiper";

const Overview = () => {
  return (
    <div className="plugin-page__overview flex flex-col">
      {/* TODO: Temporarily disabled */}
      {/* <Gallery /> */}

      <div className="flex my-6">
        <div className="basis-2/3 border-r">
          <div className="px-6 mt-8 mr-10">
            <h3 className="text-xl font-bold text-gray-900">Overview</h3>
          </div>

          <Form />
        </div>

        <AdditionalInfo />
      </div>
    </div>
  );
};

export default Overview;
