import React, { useState, useContext, useMemo } from "react";

const ApplicationContext = React.createContext(undefined);

const useApplicationContext = () => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationContext must be used within a ApplicationProvider"
    );
  }

  return context;
};

const ApplicationContextProvider = ({ children }) => {
  const [searchInput, setSearchInput] = useState("");

  const memoizedSearchInput = useMemo(() => searchInput, [searchInput]);

  return (
    <ApplicationContext.Provider
      value={{
        memoizedSearchInput,
        setSearchInput,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export { useApplicationContext, ApplicationContextProvider };
