import React from "react";

type Props = {
  person: string;
  preview?: boolean;
  setSelected?: (person: string | undefined) => void;
  removeContributor?: (person: string) => Promise<any>;
  isAdmin: boolean;
};

const Collaborator = ({
  person,
  preview,
  setSelected,
  removeContributor,
  isAdmin,
}: Props) => {
  return (
    <div className="flex" onClick={() => setSelected && setSelected(person)}>
      <div className="flex items-center">
        <h4 className="text-lg font-bold">{person}</h4>
      </div>

      {!preview && isAdmin && (
        <button
          className="self-center text-gray-600 ml-auto"
          onClick={() => removeContributor && removeContributor(person)}
        >
          Remove
        </button>
      )}
    </div>
  );
};

export default Collaborator;
