import { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { usePluginContext } from "../../../contexts/pluginContext";
import { useLoginContext } from "../../../contexts/loginContext";
import { useQueryClient } from "@tanstack/react-query";

import Variants from "./variants";
import Spinner from "../../spinner";

import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleUpIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/24/outline";
import NewVariants from "../overview/newVariants";

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const Versions = () => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { BASE_URL } = useLoginContext();
  const { memoizedPluginData } = usePluginContext();

  const handleChangeChannel = async (path, version) => {
    setLoading(true);
    return await axios
      .get(`${BASE_URL}/${path}/${memoizedPluginData?.name}/${version}`)
      .then(() => {
        queryClient
          .invalidateQueries([`plugin-${memoizedPluginData?.name}`])
          .then(() => setLoading(false));
      });
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-10">
      {memoizedPluginData?.versions.map((item, index) => {
        const thisDate = dayjs(item?.updated, "D-M-YYYY");
        const timeAgo = dayjs().to(thisDate);

        return (
          <div className="flex my-6" key={index}>
            <div className="flex flex-col basis-1/5">
              <div className="mb-1 font-medium">{timeAgo}</div>

              <div className="flex mb-1">
                <ArrowDownOnSquareIcon width={20} height={20} />

                <span className="ml-1">{item?.downloads}</span>
              </div>

              <div className="flex items-center mb-1">
                <TagIcon width={20} height={20} />

                <span className="ml-1">v{item?.version}</span>
              </div>
            </div>

            <div className="flex flex-col basis-4/5 p-3 pt-5 border rounded">
              <div className="border-b border-gray-200 pb-3">
                <span className="text-2xl font-bold">
                  v{item?.version} Release
                </span>

                {index === 0 && (
                  <div className="ml-4 text-sm border border-volumio-green py-1 px-3 inline-block rounded-full text-volumio-green">
                    Latest
                  </div>
                )}

                <div className="pt-3">
                  <span className="font-medium">Volumio version: </span>
                  <span className="capitalize">{item?.volumioVersion}</span>
                </div>

                <div className="pt-3 flex items-center">
                  <div className="absolute w-60 z-10 bg-white opacity-50">
                    {loading && <Spinner />}
                  </div>

                  <span className="font-medium">Channel: </span>
                  <span className="capitalize mr-5 ml-2">{item?.channel}</span>

                  {item.channel.toLowerCase() === "beta" ? (
                    <div
                      className="change-versions bg-gray-200 rounded p-1 hover:flex items-center justify-center border cursor-pointer overflow-hidden"
                      onClick={() =>
                        handleChangeChannel("movetostable", item?.version)
                      }
                    >
                      <ChevronDoubleUpIcon width={20} height={20} />

                      <span className="ml-2 whitespace-nowrap ">
                        Move to Stable
                      </span>
                    </div>
                  ) : (
                    <div
                      className="change-versions bg-gray-200 rounded p-1 hover:flex items-center justify-center border cursor-pointer overflow-hidden"
                      onClick={() =>
                        handleChangeChannel("movetobeta", item?.version)
                      }
                    >
                      <ChevronDoubleDownIcon width={20} height={20} />

                      <span className="ml-2 whitespace-nowrap">
                        Move to Beta
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <Variants variants={item?.variants} />

              <NewVariants version={item?.version} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Versions;
