import { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "@tanstack/react-location";
import { logout } from "../firebase";
import { useLoginContext } from "../contexts/loginContext";
import { useApplicationContext } from "../contexts/applicationProvider";
import shutdownIcon from "../assets/images/shutdown.svg";

const Header = () => {
  const { searchInput, setSearchInput } = useApplicationContext();
  const { setFirebaseToken, removeTokenFromStorage } = useLoginContext();
  const location = useLocation();
  const navigate = useNavigate();
  const userNavigation = [{ name: "Sign out", href: "#" }];

  const handleSignout = () => {
    try {
      removeTokenFromStorage();
      logout();

      setFirebaseToken();

      navigate({ to: "/", replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  // @ts-ignore
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <header className="bg-white z-10">
      <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
        <div className="flex flex-1 justify-between px-4 sm:px-6">
          {/* Search  */}
          <div className="flex flex-1">
            {location.current.pathname === "/dashboard" && (
              <div className="flex w-full md:ml-0" action="#" method="GET">
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    name="search-field"
                    id="search-field"
                    className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                    placeholder="Search"
                    type="search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none">
                  <span className="sr-only">Open user menu</span>

                  <img src={shutdownIcon} alt="Logout" width={25} height={25} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <button
                          href={item.href}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full"
                          )}
                          onClick={handleSignout}
                        >
                          {item.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            {/* TODO: Plus button to add plugins - LATER */}
            {/* <button
              type="button"
              className="flex items-center justify-center rounded-full bg-volumio-green p-1 text-white hover:bg-volumio-green focus:outline-none focus:ring-2 focus:ring-volumio-green focus:ring-offset-2"
            >
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
              <span className="sr-only">Add file</span>
            </button> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
