import { Fragment } from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  LinkIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";

import { Link } from "@tanstack/react-location";
import { usePluginContext } from "../../contexts/pluginContext";

const PluginHeader = () => {
  const { memoizedPluginData } = usePluginContext();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  // Repository can come as a url beginning with "git+" which is invalid, so we replace it below
  const repo =
    typeof memoizedPluginData?.repository === "string"
      ? memoizedPluginData?.repository
      : memoizedPluginData?.repository?.url?.replace("git+", "");

  return (
    <div className="lg:flex lg:items-center lg:justify-between px-6">
      <div className="min-w-0 flex-1 ">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link
                  to="/dashboard"
                  className="text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  Plugins
                </Link>
              </div>
            </li>

            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <a
                  href="#"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {memoizedPluginData?.prettyName}
                </a>
              </div>
            </li>
          </ol>
        </nav>

        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {memoizedPluginData?.prettyName}
          <PencilIcon
            className="pencil-icon -ml-7 mr-2 h-5 w-5 text-gray-500 inline-block -z-10"
            aria-hidden="true"
          />
        </h2>
      </div>

      {repo !== undefined && (
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="ml-3 hidden sm:block">
            <a
              href={repo}
              target="_blank"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-volumio-green focus:ring-offset-2"
              rel="noreferrer"
            >
              <LinkIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              View
            </a>
          </span>
        </div>
      )}
    </div>
  );
};

export default PluginHeader;
