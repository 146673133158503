import React, { useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";

const CustomTextarea = ({ value, label, htmlFor, handleUpdateData }) => {
  const [newValue, setNewValue] = useState(value);

  const setNewVal = () => {
    handleUpdateData(newValue);
  };

  return (
    <div>
      <label htmlFor={htmlFor} className="relative">
        <span className="pr-3 font-medium align-top">{label}</span>

        <div>
          <textarea
            id={htmlFor}
            name={htmlFor}
            className="plugin-page__textarea bg-gray-50"
            rows={10}
            cols={100}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            onBlur={() => {
              setNewVal();
            }}
          />
          <PencilIcon
            className="pencil-icon mr-2 h-5 w-5 text-gray-500 my-auto ml-4 absolute top-1/2 pointer-events-none"
            aria-hidden="true"
          />
        </div>
      </label>
    </div>
  );
};

export default CustomTextarea;
