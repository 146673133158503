import Spinner from "../../spinner";
import VariantInput from "./variantInput";

const VariantsTable = ({
  variants,
  architectures,
  isLoading,
  setSelectedCombination,
}) => {
  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="block text-sm font-medium text-gray-700">
            Variants:
          </div>
          <p className="mt-2 text-sm text-gray-700">You may select multiple</p>
        </div>
      </div>

      <div
        className={`mt-8 flex flex-col relative  ${
          isLoading && "opacity-50 -z-0"
        }`}
      >
        {isLoading && (
          <div className="absolute z-10 top-0 right-0 bottom-0 left-0">
            <Spinner />
          </div>
        )}

        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    ></th>
                    {variants?.map((variant) => {
                      return (
                        <th
                          key={variant.name}
                          scope="col"
                          className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {variant.prettyName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {architectures?.map((architecture) => (
                    <tr
                      key={architecture.name}
                      className="divide-x divide-gray-200"
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 bg-gray-50">
                        {architecture.prettyName}
                      </td>
                      {variants.map((variant, index) => {
                        return (
                          <td
                            key={index}
                            className="whitespace-nowrap p-4 text-sm text-gray-500"
                          >
                            <div className="flex h-5 items-center">
                              <VariantInput
                                variant={variant}
                                architecture={architecture}
                                index={index}
                                setSelectedCombination={setSelectedCombination}
                              />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantsTable;
