import React from "react";
import { usePluginContext } from "../../../contexts/pluginContext";

export const AdditionalInfo = () => {
  const { memoizedPluginData } = usePluginContext();

  return (
    <div className="flex flex-col basis-1/3">
      <div className="mt-8 px-6 mr-10">
        <h3 className="text-xl font-bold text-gray-900">
          Additional Information
        </h3>
      </div>

      <div className="flex flex-col space-y-6 divide-gray-200 p-6 2xl:p-11">
        <div>
          <p className="font-medium text-xl">Version</p>
          <p>{memoizedPluginData?.version}</p>
        </div>

        <div>
          <p className="font-medium text-xl">Updated</p>
          <p>{memoizedPluginData?.updated}</p>
        </div>

        <div>
          <p className="font-medium text-xl">Versions</p>
          <p>{memoizedPluginData?.versions?.length} versions</p>
        </div>

        <div>
          <p className="font-medium text-xl">Variants</p>
          {memoizedPluginData?.variants?.map((variant) => (
            <p key={variant}>{variant}</p>
          ))}
        </div>

        <div>
          <p className="font-medium text-xl">Author</p>
          <p>{memoizedPluginData?.author}</p>
        </div>
      </div>
    </div>
  );
};
