import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLoginContext } from "../contexts/loginContext";

const useMyPlugins = () => {
  const { token, BASE_URL, checkToken } = useLoginContext();

  const fetchPlugins = async () => {
    const { data } = await axios
      .get(`${BASE_URL}/myplugins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          checkToken();
        }
      });

    return data;
  };

  const { data, error, isError, isLoading } = useQuery(
    ["myPlugins"],
    fetchPlugins,
    { enabled: !!token }
  );

  return { data, error, isError, isLoading };
};

export default useMyPlugins;
