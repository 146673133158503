import React, { useMemo, useState } from "react";
import { MyPluginsList } from "../pluginsType";
import { useNavigate } from "@tanstack/react-location";
import useMyPlugins from "../queries/useMyPlugins";
import { useApplicationContext } from "../contexts/applicationProvider";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { Categories } from "../components/dashboard/categories";
import { Category } from "../categoryTypes";
import TableView from "../components/dashboard/tableView";
import LoadingTable from "../components/loadingTable";

const MemoTable = React.memo(TableView);

const Dashboard = () => {
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [showFilter, setShowFilter] = useState(false);
  const { data, error, isError, isLoading } = useMyPlugins();

  const categoryName: string = useMemo(
    () => category?.name || "",
    [category?.name]
  );

  const navigate = useNavigate();
  const { memoizedSearchInput } = useApplicationContext();

  const keyword: string = useMemo(
    () => memoizedSearchInput,
    [memoizedSearchInput]
  );

  const myPlugins: MyPluginsList = useMemo(() => data, [isLoading]);

  const goToPlugin = useMemo(
    () => (path: string) => {
      navigate({ to: path, replace: false });
    },
    []
  );

  return (
    <>
      <div className="flex h-full">
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
              >
                <div className="flex w-full justify-between items-center border-b border-gray-200 py-5 px-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    My Plugins
                  </h3>

                  <div
                    className={`${
                      !!category ? "enlarge" : "reduce  flex-row-reverse"
                    } bg-gray-200 flex justify-between h-10 items-center py-2 pl-2 text-xs rounded-md overflow-hidden`}
                  >
                    <>
                      {!!category && (
                        <div className="mr-3 flex flex-nowrap items-center whitespace-nowrap">
                          <span>Filtering by: </span>

                          <span className="ml-2 capitalize font-medium bg-volumio-green text-white py-1 px-2 rounded-md">
                            {category?.prettyName}
                          </span>
                          <XMarkIcon
                            className={`ml-1 h-5 w-5 flex-none text-gray-400 hover:text-black `}
                            aria-hidden="true"
                            onClick={() => setCategory(undefined)}
                          />
                        </div>
                      )}
                    </>

                    <button
                      className="flex items-center justify-center overflow-hidden"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <FunnelIcon
                        className={`mr-2 h-5 w-5 flex-none  ${
                          showFilter ? "text-volumio-green" : "text-gray-400"
                        } group-hover:text-gray-500`}
                        aria-hidden="true"
                      />
                      <div className="sr-only">Filter</div>
                    </button>
                  </div>
                </div>
                <>
                  {isLoading ? (
                    <>
                      {[...new Array(8)].map((item, index) => (
                        <div key={index}>
                          <LoadingTable />
                          <LoadingTable />
                          <LoadingTable />
                        </div>
                      ))}
                    </>
                  ) : isError ? (
                    <>
                      <h3 className="text-lg font-medium leading-6 text-gray-900 pt-4">
                        Error getting your plugins.
                      </h3>
                      <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Ducimus maxime natus numquam aperiam ipsam magni
                        porro delectus incidunt quibusdam voluptates. Temporibus
                        molestiae maiores sequi cupiditate repellat tempora
                        repellendus, inventore quia.
                      </p>
                    </>
                  ) : !!data && data.length !== 0 ? (
                    <div className="overflow-hidden sm:rounded-md p-2 pb-10">
                      <Categories
                        category={category}
                        setCategory={setCategory}
                        showFilter={showFilter}
                      />

                      {/* ===== Table Preview ===== */}
                      <MemoTable
                        myPlugins={myPlugins}
                        categoryName={categoryName}
                        keyword={keyword}
                        searchInput={memoizedSearchInput}
                        goToPlugin={goToPlugin}
                      />
                    </div>
                  ) : (
                    <div className="p-11">
                      <h3 className="font-medium leading-6 text-gray-900">
                        No plugins yet.
                      </h3>
                      <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        It seems there aren't any plugins that you are managing,
                        or that you are allowed to modify.
                      </p>
                    </div>
                  )}
                </>
              </section>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
