import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLoginContext } from "../contexts/loginContext";

const useGetOs = () => {
  const { token, BASE_URL } = useLoginContext();

  const fetchOs = async () => {
    const { data } = await axios.get(`${BASE_URL}/os`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  };

  const { data, error, isError, isLoading } = useQuery(["os"], fetchOs, {
    enabled: !!token,
  });

  return { data, error, isError, isLoading };
};

export default useGetOs;
