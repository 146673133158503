import React from "react";
import { usePluginContext } from "../../contexts/pluginContext";

const tabs = ["Overview", "Collaboration", "Publish"];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="px-6 my-10">
      <nav
        className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
        aria-label="Tabs"
      >
        {tabs.map((tab, tabIdx) => (
          <p
            onClick={() => setActiveTab(tabIdx)}
            key={tab}
            className={classNames(
              activeTab === tabIdx
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              tabIdx === 0 ? "rounded-l-lg" : "",
              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
            )}
          >
            <span>{tab}</span>
            <span
              aria-hidden="true"
              className={classNames(
                activeTab === tabIdx ? "bg-volumio-green" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </p>
        ))}
      </nav>
    </div>
  );
};

export default Tabs;
