import React from "react";

const LoadingTable = () => {
  return (
    <div role="status" className="animate-pulse p-3 mt-4">
      <div className="flex items-center w-full space-x-8">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-60"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-44"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
      </div>
    </div>
  );
};

export default LoadingTable;
