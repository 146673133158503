import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLoginContext } from "../contexts/loginContext";

const useGetVariants = () => {
  const { token, BASE_URL } = useLoginContext();

  const fetchVariants = async () => {
    const { data } = await axios.get(`${BASE_URL}/variants`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  };

  const { data, error, isError, isLoading } = useQuery(
    ["variants"],
    fetchVariants,
    {
      enabled: !!token,
    }
  );

  return { data, error, isError, isLoading };
};

export default useGetVariants;
