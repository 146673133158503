import React, { useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";

const CustomInput = ({ value, label, htmlFor, handleUpdateData }) => {
  const [newValue, setNewValue] = useState(value);

  const setNewVal = () => {
    handleUpdateData(newValue);
  };

  return (
    <div className={"border-4 border-transparent p-1"}>
      <label
        htmlFor={htmlFor}
        className="relative flex items-center justify-between ring-transparent ring-offset-transparent"
      >
        <span className="pr-3 font-medium">{label}</span>
        <input
          id={htmlFor}
          name={htmlFor}
          className="plugin-page__input ring-transparent ring-offset-transparent"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onBlur={() => {
            setNewVal();
          }}
        />
        <PencilIcon
          className="pencil-icon ml-1 h-5 w-5 text-gray-500 absolute right-1 pointer-events-none"
          aria-hidden="true"
        />
      </label>
    </div>
  );
};

export default CustomInput;
