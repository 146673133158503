import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useLoginContext } from "../../../contexts/loginContext";
import { usePluginContext } from "../../../contexts/pluginContext";
import useGetArchitectures from "../../../queries/useGetArchitectures";
import useGetOs from "../../../queries/useOs";
import useGetVariants from "../../../queries/useVariant";
import ChooseOs from "./chooseOsDropdown";
import VariantsTable from "./variantsTable";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const NewVariants = ({ version }) => {
  const queryClient = useQueryClient();
  const [selectedOs, setSelectedOs] = useState<any>();
  const [selectedCombination, setSelectedCombination] = useState<any>([]);
  const [completedCalls, setCompletedCalls] = useState(0);
  const [postingVariants, setPostingVariants] = useState(false);
  const [active, setActive] = useState(false);

  const progress = () =>
    Math.floor((completedCalls / selectedCombination.length) * 100) || 0;

  const percentage = progress();

  useEffect(() => {
    if (percentage === 100) {
      setTimeout(() => {
        setCompletedCalls(0);
        setPostingVariants(false);
      }, 500);
    }
  }, [percentage]);

  const { memoizedPluginData } = usePluginContext();
  const { BASE_URL, token } = useLoginContext();

  const { data: os } = useGetOs();
  const { data: variants } = useGetVariants();
  const { data: architectures, isLoading: architecturesLoading } =
    useGetArchitectures();

  const handlePostVariants = async (): Promise<any> => {
    setPostingVariants(true);

    for (let i = 0; i < selectedCombination.length; i++) {
      await axios
        .post(
          `${BASE_URL}/variant/${memoizedPluginData.name}/${version}/${selectedCombination[i].name}/${selectedOs?.name}/${selectedCombination[i].architecture.name}/volumio/${selectedOs?.name}/${selectedCombination[i].architecture.name}`,
          {},
          {
            headers: {
              authoriazation: `Bearer ${token}`,
            },
          }
        )
        .then(() => setCompletedCalls((prev) => prev + 1))
        .catch((e) => {
          console.log(e);
          setCompletedCalls((prev) => prev + 1);
        });
    }
  };

  const mutation = useMutation(handlePostVariants, {
    onSuccess: () => {
      queryClient.invalidateQueries([`plugin-${memoizedPluginData.name}`]);
    },
  });

  return (
    <div className="relative">
      <div
        className="flex items-center mt-3 cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <>
          {active ? (
            <ChevronDownIcon width={20} height={20} />
          ) : (
            <ChevronRightIcon width={20} height={20} />
          )}
        </>

        <span className="text-lg font-semibold ml-1 mr-2">Add New Variant</span>
      </div>

      <>
        {active && (
          <>
            {postingVariants && (
              <div className="absolute top-0 right-0 bottom-0 left-0 z-10 text-2xl grid place-items-center">
                <div className="w-64 h-64 bg-white rounded-full">
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textSize: "15px",
                      pathTransitionDuration: 0.5,
                      pathColor: `#51B289`,
                      textColor: `#51B289`,
                      trailColor: `#919191`,
                    })}
                  />
                </div>
              </div>
            )}

            <div
              className={`px-8 mt-2 ${
                postingVariants ? "opacity-30 pointer-events-none" : ""
              }`}
            >
              <h3 className="text-xl mb-3 font-bold text-gray-900">
                OS/Architecture/Variant
              </h3>

              <div className="select-variants flex ">
                <div className="flex flex-col px-3 w-full basis-2/3">
                  <ChooseOs
                    data={os}
                    selectedOs={selectedOs}
                    setSelectedOs={setSelectedOs}
                  />

                  {selectedOs?.name && (
                    <VariantsTable
                      variants={variants}
                      architectures={architectures}
                      isLoading={architecturesLoading}
                      setSelectedCombination={setSelectedCombination}
                    />
                  )}
                </div>

                <div className="basis-1/3 flex flex-col ">
                  {selectedCombination?.length !== 0 && (
                    <>
                      <div className="mt-8 px-6 mr-10">
                        <h3 className="text-xl font-bold text-gray-900">
                          Selected Variants
                        </h3>
                      </div>

                      <ul className="p-11">
                        {selectedCombination.map((item, index) => (
                          <li
                            key={`${item.name}_${index}`}
                            className="flex items-center"
                          >
                            <div className="w-2 h-2 bg-black rounded-full mr-3"></div>
                            {item.name}/{selectedOs?.name}/
                            {item.architecture.name}
                          </li>
                        ))}
                      </ul>

                      <div className="p-11">
                        <button
                          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-volumio-green hover:text-white focus:outline-none focus:ring-2 focus:ring-volumio-green focus:ring-offset-2"
                          onClick={() => mutation.mutate()}
                        >
                          Add variants
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default NewVariants;
