import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = initializeApp(config);
const auth = getAuth(app);
const db = getFirestore(app);
let error: any = null;

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);

    error = null
    return user;
  } catch (err: any) {
    if (err) {
      error = err?.code
    }
  }
};

const refreshToken = async () => {
  return await auth.currentUser?.getIdToken(true)
}

const logout = async () => {
  await signOut(auth)
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  logout,
  refreshToken,
  error
};