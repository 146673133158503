import { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-location";
import { auth, logInWithEmailAndPassword, error } from "../firebase";
import { useLoginContext } from "../contexts/loginContext";
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from "../components/spinner";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

const Login = () => {
  const { setFirebaseToken, setUser } = useLoginContext();
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) return;
    setLoading(true);
    await logInWithEmailAndPassword(email, password);

    if (user !== null && !loading) {
      setFirebaseToken(user.accessToken);
      setUser(user.email);

      navigate({ to: "/dashboard", replace: true });
      return setLoading(false);
    }

    if (error !== null) setHasError(true);
    setLoading(false);
  };

  useEffect(() => {
    handleLogin();
  }, [user]);

  useEffect(() => {
    setHasError(false);
  }, [email, password]);

  return (
    <div className="grid place-items-center p-11 h-full">
      <div className=" h-1/2 flex flex-col justify-evenly w-3/5 2xl:w-2/5">
        <div className=" flex justify-center">
          <img
            src={require("../assets/images/volumio_logo_grey.png")}
            width={500}
            alt="Logo"
            className="mb-5"
          />
        </div>

        <form className="flex flex-col items-center lg:my-5 xl:my-0">
          {loading && (
            <div className="absolute bg-white opacity-60 z-10 w-1/2 h-60">
              <Spinner />
            </div>
          )}

          <label
            htmlFor="email"
            className="flex text-sm font-medium text-gray-700 items-center justify-between mb-3 w-3/5 relative"
          >
            <span className="mr-4">Email:</span>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={false}
              className={`block rounded-md w-72 ${
                hasError
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                  : "border-gray-300 shadow-sm focus:border-volumio-green focus:ring-volumio-green disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
              }`}
              placeholder="user@email.com"
            />
            {hasError && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500 bg-white"
                  aria-hidden="true"
                />
              </div>
            )}
          </label>

          <label
            htmlFor="password"
            className="flex text-sm font-medium text-gray-700 items-center justify-between w-3/5 relative"
          >
            <span className="mr-4">Password:</span>
            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={false}
              className={`block rounded-md w-72 ${
                hasError
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                  : "border-gray-300 shadow-sm focus:border-volumio-green focus:ring-volumio-green disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
              }`}
            />
            {hasError && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500 bg-white"
                  aria-hidden="true"
                />
              </div>
            )}
          </label>

          <p
            className={`text-sm text-red-600 mt-8 ${!hasError && "invisible"}`}
            id="email-error"
          >
            {error === "auth/too-many-requests"
              ? "Too many requests, try again in 1 minute"
              : "Invalid Credendtials"}
          </p>

          <button
            className="px-5 py-2 mt-3 mx-auto bg-volumio-green rounded text-white"
            onClick={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
