import {
  Link,
  Outlet,
  ReactLocation,
  Router,
  useRouter,
} from "@tanstack/react-location";
import { ReactLocationDevtools } from "@tanstack/react-location-devtools";

import { useLoginContext } from "../contexts/loginContext";
import Header from "../components/header";
import Login from "../login/Login";
import Dashboard from "../screens/Dashboard";
import PluginScreen from "../screens/PluginScreen";

const NotFound = () => {
  return <div className="p-11 font-bold text-xl">Page Not found</div>;
};

const RootNavigation = () => {
  const location = new ReactLocation();
  const routes = [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "plugin",
      children: [
        {
          path: "/:pluginName",
          element: <PluginScreen />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const Spinner = () => {
    return <div className={`inline-block animate-spin px-3`}>⍥</div>;
  };

  const Root = () => {
    // We can access the router state, even though
    // we're not rendering any routes yet
    const router = useRouter();
    const { firebaseToken } = useLoginContext();

    return (
      <div className={`min-h-screen flex flex-col`}>
        <div className={`flex-1 flex`}>
          {!!firebaseToken && (
            <div className="hidden w-28 h-screen z-10 bg-gray-100 md:block fixed">
              <div className="flex w-full flex-col items-center py-6">
                <div className="flex flex-shrink-0 items-center pb-4">
                  <img
                    className="h-8 w-auto"
                    src={require("../assets/images/logo-circle-grey.png")}
                    alt="Volumio Icon"
                  />
                </div>

                {!!firebaseToken &&
                  [["dashboard", "Dashboard"]].map(([to, label]) => {
                    return (
                      <div key={to}>
                        <Link
                          to={to}
                          className={` hover:bg-volumio-green hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium`}
                          // Make "active" links bold
                          getActiveProps={() => ({
                            className: `bg-volumio-green text-white`,
                          })}
                          activeOptions={{
                            // If the route points to the root of it's parent,
                            // make sure it's only active if it's exact
                            exact: to === ".",
                          }}
                        >
                          {label}
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          <div className={`flex-1 border-l border-gray-200 overflow-y-scroll`}>
            {!!firebaseToken ? (
              <>
                <div className="main-container">
                  <Header />
                </div>

                <div className="main-container pt-16">
                  <Outlet />
                </div>
              </>
            ) : (
              <Login />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Router
        location={location}
        routes={routes}
        defaultPendingElement={
          <div className={`text-2xl`}>
            <Spinner />
          </div>
        }
      >
        <Root />
      </Router>
    </>
  );
};

export default RootNavigation;
