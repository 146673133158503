import React from "react";
import { CategoriesType } from "../../categoryTypes";
import useCategories from "../../queries/useCategories";

export const Categories = ({ category, setCategory, showFilter }) => {
  const { data } = useCategories();
  const categories: CategoriesType = data;

  return (
    <div className={`categories ${!showFilter ? "open" : "closed"} -z-10`}>
      <div className="flex justify-center items-center my-3 -z-10">
        <div className="flex xl:justify-end">
          {categories?.map((categorie) => (
            <button
              key={categorie.name}
              className={`relative first-of-type:ml-0 last-of-type:mr-0 inline-flex justify-center items-center rounded-md border border-gray-300 bg-white hover:bg-volumio-green hover:text-white w-32 xl:w-36  py-2 text-xs font-medium text-gray-700 shadow-md  focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-2 mx-1 capitalize ${
                categorie?.name === category?.name &&
                " bg-volumio-green text-white "
              }`}
              onClick={() => {
                if (category === "") {
                  return setCategory(categorie);
                } else if (category?.name === categorie?.name) {
                  return setCategory("");
                } else {
                  return setCategory(categorie);
                }
              }}
            >
              {categorie?.prettyName}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
