import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { PluginType } from "../../../pluginType";
import CustomInput from "./customInput";
import CustomTextarea from "./customTextarea";
import { useLoginContext } from "../../../contexts/loginContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../spinner";
import { usePluginContext } from "../../../contexts/pluginContext";

export const Form = () => {
  const { memoizedPluginData, isLoading } = usePluginContext();
  const { token, BASE_URL } = useLoginContext();
  const [newData, setNewData] = useState<any>(memoizedPluginData);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoading) setNewData(memoizedPluginData);
  }, [isLoading]);

  const notifyError = (error) => {
    toast.warn(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const notifySuccess = () => {
    toast.success("Edit successful", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const mutation = useMutation(async (value: PluginType) => {
    setLoading(true);

    try {
      await axios.put(
        `${BASE_URL}/plugin/${memoizedPluginData?.name}`,
        { ...value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      queryClient.invalidateQueries([`plugin-${memoizedPluginData?.name}`]);

      setLoading(false);
      return notifySuccess();
    } catch (error: any) {
      if (
        error &&
        error?.response?.data === "Not alowed to update this plugin"
      ) {
        setLoading(false);
        return notifyError("Not alowed to update this plugin!");
      }
    }
  });

  const putNewmemoizedPluginData = async () => {
    if (newData !== undefined) mutation.mutate(newData);
  };

  useEffect(() => {
    if (
      newData.prettyName === memoizedPluginData.prettyName &&
      newData.icon === memoizedPluginData.icon &&
      newData.description === memoizedPluginData.description &&
      newData.details === memoizedPluginData.details &&
      newData.changelog === memoizedPluginData.changelog
    ) {
      return;
    }

    putNewmemoizedPluginData();
  }, [newData]);

  const handleUpdateName = (name) => {
    if (memoizedPluginData?.prettyName === name) return;

    setNewData((data) => {
      if (data) return { ...data, prettyName: name };
    });
  };

  const handleUpdateIcon = (icon) => {
    if (memoizedPluginData?.icon === icon) return;

    setNewData((data) => {
      if (data) return { ...data, icon: icon };
    });
  };

  const handleUpdateDescription = (description) => {
    if (memoizedPluginData?.description === description) return;

    setNewData((data) => {
      if (data) return { ...data, description: description };
    });
  };

  const handleUpdateDetails = (details) => {
    if (memoizedPluginData?.details === details) return;

    setNewData((data) => {
      if (data) return { ...data, details: details };
    });
  };

  const handleUpdateChangelog = (changelog) => {
    if (memoizedPluginData?.changelog === changelog) return;

    setNewData((data) => {
      if (data) return { ...data, changelog: changelog };
    });
  };

  return (
    <form className="p-6 2xl:p-11">
      <ToastContainer autoClose={1500} />

      <div
        className={`mb-6 space-y-6 w-1/2 relative ${
          isLoading || loading ? "opacity-60" : ""
        }`}
      >
        {isLoading || loading ? (
          <div className="absolute top-0 right-0 bottom-0 left-0 z-10">
            <Spinner />
          </div>
        ) : (
          <></>
        )}

        <CustomInput
          label="Name:"
          htmlFor="prettyName"
          value={memoizedPluginData.prettyName}
          handleUpdateData={handleUpdateName}
        />

        <CustomInput
          label="Icon:"
          htmlFor="icon"
          value={memoizedPluginData.icon}
          handleUpdateData={handleUpdateIcon}
        />
      </div>

      <div
        className={`space-y-6 relative ${
          isLoading || loading ? "opacity-60" : ""
        }`}
      >
        {isLoading ||
          (loading && (
            <div className="absolute top-0 right-0 bottom-0 left-0 z-10">
              <Spinner />
            </div>
          ))}

        <CustomTextarea
          label="Description:"
          htmlFor="description"
          value={memoizedPluginData.description}
          handleUpdateData={handleUpdateDescription}
        />

        <CustomTextarea
          label="Details:"
          htmlFor="Details"
          value={memoizedPluginData.details}
          handleUpdateData={handleUpdateDetails}
        />

        <CustomTextarea
          label="Changelog:"
          htmlFor="changelog"
          value={memoizedPluginData.changelog}
          handleUpdateData={handleUpdateChangelog}
        />
      </div>
    </form>
  );
};
