import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLoginContext } from "../contexts/loginContext";

const usePlugin = (pluginName) => {
  const { token, BASE_URL, checkToken } = useLoginContext();

  const fetchPlugins = async () => {
    const { data } = await axios
      .get(`${BASE_URL}/plugin/${pluginName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return checkToken();
        }
      });

    return data;
  };

  const { data, error, isError, isLoading, isFetching } = useQuery(
    [`plugin-${pluginName}`],
    fetchPlugins,
    { enabled: !!token }
  );

  return { data, error, isError, isLoading, isFetching };
};

export default usePlugin;
