import { useState } from "react";
import { usePluginContext } from "../../contexts/pluginContext";

import PluginHeader from "./pluginHeader";
import Spinner from "../spinner";
import Tabs from "./pluginTabs";
import Overview from "./overview/overview";
import Collaboration from "./collaboration/collaboration";
import Versions from "./versions/versions";

const Plugin = () => {
  const { isLoading, isError } = usePluginContext();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="plugin-page mx-auto h-screen pt-5">
      <>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          `Error getting plugin`
        ) : (
          <>
            <PluginHeader />

            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

            {activeTab === 0 ? (
              <Overview />
            ) : activeTab === 1 ? (
              <Collaboration />
            ) : activeTab === 2 ? (
              <Versions />
            ) : (
              <></>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Plugin;
