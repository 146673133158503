import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLoginContext } from "../../../contexts/loginContext";
import { usePluginContext } from "../../../contexts/pluginContext";

import Spinner from "../../spinner";
import AddCollaboratorModal from "./addCollaboratorModal";
import Collaborator from "./collaborator";
import UserSearch from "./userSearch";

const Collaboration = () => {
  const queryClient = useQueryClient();
  const { memoizedPluginData, isFetching } = usePluginContext();
  const contributors = memoizedPluginData?.contributors;

  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user, BASE_URL, token } = useLoginContext();

  useEffect(() => {
    if (user === memoizedPluginData?.owner) setIsAdmin(true);
  }, []);

  const removeContributor = async (person: string): Promise<any> => {
    await axios
      .delete(`${BASE_URL}/contributor/${memoizedPluginData?.name}/${person}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        queryClient.invalidateQueries([`plugin-${memoizedPluginData?.name}`]);
      });
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl border rounded p-5">
        {isAdmin && (
          <div className="flex justify-between content-center border-b mb-8">
            <h3 className="text-xl font-bold text-gray-900 ">Manage Access</h3>

            <button
              className="font-bold text-white mb-8 bg-green-600 py-2 px-4 rounded text-xs disabled:opacity-40"
              onClick={() => setOpen(true)}
              disabled={isFetching ? true : false}
            >
              Add people
            </button>
          </div>
        )}

        {contributors?.length !== 0 && <UserSearch />}

        <div className="relative">
          {isFetching && (
            <div className="absolute top-0 right-0 bottom-0 left-0 z-10 ">
              <Spinner />
            </div>
          )}

          {contributors?.length !== 0 ? (
            <div
              className={`overflow-hidden rounded-md border border-gray-300 bg-white ${
                isFetching && "opacity-40"
              }`}
            >
              <ul className="divide-y divide-gray-300">
                {contributors?.map((contributor) => (
                  <li key={contributor} className="px-6 py-4">
                    <Collaborator
                      person={contributor}
                      removeContributor={removeContributor}
                      isAdmin={isAdmin}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div
              className={`flex justify-center items-center overflow-hidden rounded-md border border-gray-300 bg-gray-50 h-72 ${
                isFetching && "opacity-40"
              }`}
            >
              No contributors yet.
            </div>
          )}
        </div>
      </div>

      <AddCollaboratorModal
        open={open}
        setOpen={setOpen}
        pluginName={memoizedPluginData?.name}
      />
    </div>
  );
};

export default Collaboration;
