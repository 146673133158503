import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { refreshToken } from "../firebase";

const LoginContext = React.createContext(undefined);

const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (context === undefined) {
    throw new Error("useLoginContext must be used within a LoginProvider");
  }

  return context;
};

const LoginContextProvider = ({ children }) => {
  const setFirebaseTokenFromStorage = () => {
    try {
      const FBToken = localStorage.getItem("fbToken");

      if (FBToken !== null) {
        return FBToken;
      }

      return null;
    } catch (error) {}
  };

  const setUserFromStorage = () => {
    try {
      const user = localStorage.getItem("user");

      if (user !== null) {
        return user;
      }

      return null;
    } catch (error) {}
  };

  const [firebaseToken, setFirebaseToken] = useState(
    setFirebaseTokenFromStorage()
  );
  const [user, setUser] = useState(setUserFromStorage());
  const [token, setToken] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (firebaseToken === undefined || firebaseToken === null) return;

    getToken();
    saveTokenToStorage();
  }, [firebaseToken]);

  const getToken = async () => {
    try {
      const { data } = await axios.get(
        `https://functions.volumio.cloud/api/v1/getCustomToken?idToken=${firebaseToken}`
      );

      setToken(data);
    } catch (error) {
      if (error.response.status === 401) {
        checkToken();
      }
    }
  };

  const saveTokenToStorage = () => {
    localStorage.setItem("fbToken", firebaseToken);
    localStorage.setItem("user", user);
  };

  const checkToken = async () =>
    await refreshToken()
      .then((result) => setFirebaseToken(result))
      .catch((e) => console.log("Error refreshing token".e));

  const removeTokenFromStorage = () => {
    localStorage.removeItem("fbToken");
    setFirebaseToken();
  };

  return (
    <LoginContext.Provider
      value={{
        BASE_URL,
        firebaseToken,
        setFirebaseToken,
        token,
        setToken,
        removeTokenFromStorage,
        user,
        setUser,
        checkToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { useLoginContext, LoginContextProvider };
