import { useState } from "react";
import {
  ArrowDownOnSquareIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";

const Variants = ({ variants }) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <div
        className="flex items-center mt-3 cursor-pointer"
        onClick={() => variants.length !== 0 && setActive(!active)}
      >
        {variants.length !== 0 && (
          <>
            {active ? (
              <ChevronDownIcon width={20} height={20} />
            ) : (
              <ChevronRightIcon width={20} height={20} />
            )}
          </>
        )}

        <span className="text-lg font-semibold ml-1 mr-2">Variants</span>

        <span className="text-xs px-2 bg-gray-200 rounded-full">
          {variants.length}
        </span>
      </div>

      {active && (
        <div className="overflow-hidden rounded-md border border-gray-300 bg-white">
          <ul role="list" className="divide-y divide-gray-300 text-sm">
            {variants.map((item) => (
              <li
                key={item.variant}
                className="flex justify-between px-6 py-4 "
              >
                <a
                  href="#"
                  className="basis-1/2 text-volumio-green hover:underline"
                >
                  {item.fileName}
                </a>
                <div className="flex items-center justify-between basis-1/3">
                  <div className="flex items-center">
                    <ArrowDownOnSquareIcon width={15} height={15} />
                    <p className="">{item.downloads}</p>
                  </div>

                  <div>{item.variant}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Variants;
