/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useLoginContext } from "../../../contexts/loginContext";

import UserSearch from "./userSearch";
import Spinner from "../../spinner";

import { AcademicCapIcon, XMarkIcon } from "@heroicons/react/24/outline";

const AddCollaboratorModal = ({ open, setOpen, pluginName }) => {
  const [searchInput, setSearchInput] = useState("");
  const { BASE_URL, token } = useLoginContext();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const queryClient = useQueryClient();

  const handleInvitePerson = async () => {
    if (searchInput.length === 0 && searchInput !== " ") return;

    setLoading(true);
    await axios
      .post(
        `${BASE_URL}/contributor/${pluginName}/${searchInput}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        setSearchInput("");
        setOpen(false);
        queryClient.invalidateQueries([`plugin-${pluginName}`]);
      })
      .catch((e) => {
        setLoading(false);
        setHasError(true);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-2/4">
                <button
                  className="ml-auto block p-1 pt-0"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon
                    className="h-6 w-6 text-gray-900 ml-auto"
                    aria-hidden="true"
                  />
                </button>

                {loading && (
                  <div className="absolute top-12 right-0 bottom-0 left-0 z-10 ">
                    <Spinner />
                  </div>
                )}

                <div className={`${loading && "opacity-40"}`}>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                    <AcademicCapIcon
                      className="h-6 w-6 text-gray-900"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add collaborators
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Invite a collaborator by typing their email.
                      </p>
                    </div>

                    <UserSearch
                      searchResult={searchInput}
                      handleChange={setSearchInput}
                      placeholder="Invite collaborators (e.g. user@email.com)"
                    />
                  </div>
                </div>

                <p
                  className={`text-sm text-red-600 ${!hasError && "invisible"}`}
                  id="email-error"
                >
                  Error adding user.
                </p>

                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-volumio-green px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-volumio-green focus:outline-none focus:ring-2 focus:ring-volumio-green focus:ring-offset-2 sm:text-sm disabled:opacity-40"
                    onClick={handleInvitePerson}
                    disabled={loading ? true : false}
                  >
                    Add
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddCollaboratorModal;
