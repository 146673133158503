import React, { useState } from "react";

const VariantInput = ({
  variant,
  architecture,
  index,
  setSelectedCombination,
}) => {
  const [selected, setSelected] = useState(false);

  const handleSelectedRows = (variant, architecture) => {
    setSelected(!selected);
    const combination = { ...variant, architecture: architecture };

    if (!selected) {
      return setSelectedCombination((prevArr) => [...prevArr, combination]);
    }

    if (selected) {
      return setSelectedCombination((prevArr) =>
        prevArr.filter(
          (item) =>
            item.name !== combination.name ||
            item.architecture.name !== combination.architecture.name
        )
      );
    }
  };

  return (
    <input
      id={`architecture-${index}`}
      aria-describedby="architecture-description"
      name={`architecture-${index}`}
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-volumio-green focus:ring-volumio-green"
      onChange={() => handleSelectedRows(variant, architecture)}
    />
  );
};

export default VariantInput;
