import React from "react";
import { UsersIcon } from "@heroicons/react/20/solid";

type Props = {
  searchResult?: string;
  handleChange?: (e: string) => void;
  placeholder?: string;
};

const UserSearch = ({ searchResult, handleChange, placeholder }: Props) => {
  return (
    <div>
      <div className="mt-1 flex rounded shadow-sm bg-slate-500 my-5">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>

          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded border-gray-300 pl-10 focus:border-volumio-green focus:ring-volumio-green sm:text-sm"
            placeholder={placeholder}
            value={searchResult}
            onChange={(e) => handleChange && handleChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
