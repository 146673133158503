import React from "react";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LoginContextProvider } from "./contexts/loginContext";

import RootNavigation from "./navigation/RootNavigation";
import { ApplicationContextProvider } from "./contexts/applicationProvider";

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="app h-full">
      <QueryClientProvider client={queryClient}>
        <LoginContextProvider>
          <ApplicationContextProvider>
            <RootNavigation />
          </ApplicationContextProvider>
        </LoginContextProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
