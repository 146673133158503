import React from "react";
import Plugin from "../components/plugin/Plugin";
import { PluginContextProvider } from "../contexts/pluginContext";

const PluginScreen = () => {
  return (
    <PluginContextProvider>
      <Plugin />
    </PluginContextProvider>
  );
};

export default PluginScreen;
