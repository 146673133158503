import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLoginContext } from "../contexts/loginContext";

const useCategories = () => {
  const { token, BASE_URL } = useLoginContext();

  const fetchCategories = async () => {
    const { data } = await axios.get(`${BASE_URL}/categories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  };

  const { data, error, isError, isLoading } = useQuery(
    ["categories"],
    fetchCategories,
    { enabled: !!token }
  );

  return { data, error, isError, isLoading };
};

export default useCategories;
