import React, { useContext, useMemo } from "react";
import { useMatch } from "@tanstack/react-location";
import { PluginType } from "../pluginType";
import usePlugin from "../queries/usePlugin";

type PluginContextType = {
  memoizedPluginData: PluginType;
  error: any;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
};

const PluginContext = React.createContext<PluginContextType>(
  {} as PluginContextType
);

const usePluginContext = () => {
  const context = useContext(PluginContext);
  if (context === undefined) {
    throw new Error("usePluginContext must be used within a PluginProvider");
  }

  return context;
};

const PluginContextProvider = ({ children }) => {
  const {
    params: { pluginName },
  } = useMatch();

  const {
    data: pluginData,
    error,
    isError,
    isLoading,
    isFetching,
  } = usePlugin(pluginName);

  const memoizedPluginData = useMemo(() => {
    if (!isLoading) {
      return pluginData;
    }
  }, [isFetching]);

  const memoizedValue = useMemo(
    () => ({ memoizedPluginData, isError, isLoading, isFetching, error }),
    [isFetching]
  );

  return (
    <PluginContext.Provider value={memoizedValue}>
      {children}
    </PluginContext.Provider>
  );
};

export { usePluginContext, PluginContextProvider };
