import React from "react";

const TableView = ({
  myPlugins,
  categoryName,
  keyword,
  searchInput,
  goToPlugin,
}) => {
  return (
    <div className="mt-8 flex flex-col  2xl:max-w-full">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className=" bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Author
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Version
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Updated
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {myPlugins.length !== 0 &&
                  myPlugins
                    ?.filter(
                      (item) => item?.category?.includes(categoryName) || ""
                    )
                    ?.filter(
                      (item) =>
                        item?.prettyName
                          .toLowerCase()
                          .includes(keyword?.toLowerCase()) ||
                        searchInput === ""
                    )
                    ?.map((item, personIdx) => (
                      <tr
                        key={item?.author + personIdx}
                        className="odd:bg-gray-50 cursor-pointer"
                        onClick={() => goToPlugin(`/plugin/${item.name}`)}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {item?.prettyName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {item?.author}
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-600">
                          {item?.description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {item?.version}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {item?.updated}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            className="text-volumio-green hover:text-volumio-green"
                            onClick={() => goToPlugin(`/plugin/${item.name}`)}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableView;
